import { get, post, get2, post2 } from "./index.js";
import ajax from "@/common/js/http";
import ajax2 from "@/common/js/http2";

// 获取首页页面配置
let getHomepage = get("/microApp/homepage");
// 获取商品列表
let getShopList = get("/microApp/homepage/listSpu");
// 查询订单列表
let getQueryOrderList = get("/microApp/order");
// 商城提交订单
let mallOrder = post("/microApp/order");
// 退款
let refund = get("/microApp/order/refund");
// 页面详情 ok
let getWebPage = get("/webPage/get");
let getArea = get("/webPage/getArea")
let getWebPageAllInfo = get("/webPage/template/get");
let getWebPageProductList = get("/get/product/list")
//获取地区
let getLocation = get2("/webpage/area/allAreaList");
// 提交订单
let order = post("/order");
// 西藏卡提交订单
let orderTibet = post("/tibet/v3/order");

// 提交订单
let getNumber = get("/pool/get");
let getNumber2 = post2("/zgdPhoneNo/query");

// 订单详情
let getOrderDetails = (id) => {
  return get(`/order/${id}`);
};
//套餐
let getTaoCan = post2("/zgdPackage/query");

// // // // // // // // // // // // // // // // // // // // // // // //

// 首页数据 ok
let getHomeData = function (argument) {
  return ajax.get(`webpage-homepage/getWebPage/${argument.userId}`);
};

// 首页2.0
let getHomeList = function (id, params) {
  return ajax.get(`/homepage/pagesV2/${id}`, { params });
};

// 获取title
let getTitle = function (id) {
  return ajax.get(`/homepage/${id}`);
};

// 查询订单
let getOrderList = get("/order/search");

// 查询订单列表 ok
let postOrderList = post("/orderQuery/list");

// 查询订单信息
let getOrderQuery = function (argument) {
  return ajax.get(`/orderQuery/getInfo?orderNum=${argument}`);
};
// get('/orderQuery/getInfo')
// 查询订单支付状态
let getOrderStatus = get("/orderQuery/getPayStatus");

// 客服信息 ok
let getServiceData = get("/customerServiceInfo/get");

// 地区列表 ok
let getAreaList = get("/area/list");
// let getAreaList = get("/webpage/area/allAreaList");

// 下单 ok
let postPayOrder = post("/orderPay/purchase");
// 吸底按钮下单
let postBottomPayOrder = post("/orderPay/purchase/xidi");
// 二次支付 ok
let postAgainPayOrder = function (argument) {
  return ajax.post(`/orderPay/payAgain?orderNo=${argument}`);
};
// post('/orderPay/payAgain')
// 获取验证码
let getNumberCode = post(`/sms/sendVerificationCode`);

let getUserNumberCode = post(`/sms/user/sendVerificationCode`);

// 滚动订单信息
let getRolling = function () {
  return ajax.get("/orderQuery/rolling");
};

// 更新身份证号（收集）
let updateIdCard = post("/orderPay/update/IdCard/info");
// 上传身份证图片
const uploadIdCard = post("/upload/only");
// 人像面
const uploadIdCardBack = post("/upload/back");
// 国徽面
const uploadIdCardFront = post("/upload/front");
// 半身照
const uploadIdCardPerson = function (data, params) {
  return ajax.post(`/upload/head`, data, { params });
};

const tibetUpload = function (data, params) {
  return ajax.post("/tibet/upload", data, { params });
};

const tibetOrder = post("/tibet/order");

const getChange = get("/change/card/receiver/info");

const postChangeCard = post(`/change/card/order`);
// get("/change/card/order")
const getAccessRecord = get("/access/record");

const apiPreOrder = post(`/tibet/v4/preSubmission`);

const apiUploadFront = function (data, params) {
  return ajax.post(`/tibet/v4/uploadCardFront`, data, { params });
};

const apiUploadBack = function (data, params) {
  return ajax.post(`/tibet/v4/uploadCardBack`, data, { params });
};

const apiUploadUser = function (data, params) {
  return ajax.post(`/tibet/v4/uploadUserPhotoAndDoOrder`, data, { params });
};

// 获取是否执行jsApi线索回传
let apiGetTransitFlag = function (id) {
  return ajax.get(`/webPage/js/transit/flag/get?id=${id}`);
};
//电话列表
let getPhoneNumber = function (id) {
  return ajax.get(`/get/number?productId=${id}`);
};

let getOrderInfo = function (orderNo) {
  return ajax.get(`/order/queryOrderPayStatus?orderNo=${orderNo}`)
}

export {
  getHomepage,
  getShopList,
  getQueryOrderList,
  mallOrder,
  refund,
  getHomeData,
  getWebPage,
  postOrderList,
  getOrderQuery,
  getOrderStatus,
  getServiceData,
  getAreaList,
  postPayOrder,
  postAgainPayOrder,
  getNumberCode,
  postBottomPayOrder,
  getRolling,
  updateIdCard,
  order,
  orderTibet,
  getNumber,
  getOrderDetails,
  uploadIdCard,
  uploadIdCardFront,
  uploadIdCardBack,
  uploadIdCardPerson,
  getHomeList,
  getTitle,
  getOrderList,
  tibetUpload,
  tibetOrder,
  getChange,
  postChangeCard,
  getAccessRecord,
  getUserNumberCode,
  getLocation,
  getNumber2,
  getTaoCan,
  apiPreOrder,
  apiUploadFront,
  apiUploadBack,
  apiUploadUser,
  apiGetTransitFlag,
  getPhoneNumber,
  getWebPageAllInfo,
  getWebPageProductList,
  getArea,
  getOrderInfo,
};
