// 错误码
export const errorCode = {
  ERR_OK: 1,
  ERR_NO: 2001,
  ERR_BUSY: 2002,
  ERR_PASSWORD: 3001,
};

export const typeList = [
  {
    name: '全部',
    value: 'all',
    checkType: true,
  },
  {
    name: 'AAA',
    value: 'AAA',
    checkType: false,
  },
  {
    name: '尾号8',
    value: 'endNumber',
    checkType: false,
  },
  {
    name: 'AABB',
    value: 'AABB',
    checkType: false,
  },
  {
    name: 'ABC',
    value: 'ABC',
    checkType: false,
  },
  {
    name: 'ABAB',
    value: 'ABAB',
    checkType: false,
  },
  {
    name: '66',
    value: '66',
    checkType: false,
  },
  {
    name: '88',
    value: '88',
    checkType: false,
  },
]

export function checkIdCard (code) {
  // var code = this.order.identityCardNum;
  var city = {
    11: "北京",
    12: "天津",
    13: "河北",
    14: "山西",
    15: "内蒙古",
    21: "辽宁",
    22: "吉林",
    23: "黑龙江 ",
    31: "上海",
    32: "江苏",
    33: "浙江",
    34: "安徽",
    35: "福建",
    36: "江西",
    37: "山东",
    41: "河南",
    42: "湖北 ",
    43: "湖南",
    44: "广东",
    45: "广西",
    46: "海南",
    50: "重庆",
    51: "四川",
    52: "贵州",
    53: "云南",
    54: "西藏 ",
    61: "陕西",
    62: "甘肃",
    63: "青海",
    64: "宁夏",
    65: "新疆",
    71: "台湾",
    81: "香港",
    82: "澳门",
    91: "国外 ",
  };
  var tip = "";
  var pass = true;
  if (
    !code ||
    !/^\d{6}(18|19|20)?\d{2}(0[1-9]|1[012])(0[1-9]|[12]\d|3[01])\d{3}(\d|[xX])$/.test(
      code
    )
  ) {
    tip = "身份证号格式错误";
    pass = false;
  } else if (!city[code.substr(0, 2)]) {
    tip = "地址编码错误";
    pass = false;
  } else {
    //18位身份证需要验证最后一位校验位
    if (code.length == 18) {
      code = code.split("");
      //∑(ai×Wi)(mod 11)
      //加权因子
      var factor = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2];
      //校验位
      var parity = [1, 0, "X", 9, 8, 7, 6, 5, 4, 3, 2];
      var sum = 0;
      var ai = 0;
      var wi = 0;
      for (var i = 0; i < 17; i++) {
        ai = code[i];
        wi = factor[i];
        sum += ai * wi;
      }
      var last = parity[sum % 11];
      if (parity[sum % 11] != code[17]) {
        tip = "校验位错误";
        pass = false;
      }
    }
  }
  console.log("身份证号", tip);
  return { pass, tip };
};

export function getAddress (res) {
  let pro_list = res;
  let cit_list = res.map((item) => item.children);
  let cou_list = res
    .map((item) => item.children)
    .map((item) => item.map((item) => item.children));
  //定义地址具体对象
  let province_list = {};
  let city_list = {};
  let county_list = {};
  //转换成城市id为键值对名，城市名称为属性
  //省份
  for (let i = 0; i < pro_list.length; i++) {
    province_list[res.map((item) => item.value)[i]] = res.map(
      (item, index) => item.label
    )[i];
  }
  //城市
  // console.log(city_list.map((item) => (item.map(it => it.value))));
  // let arr = city_list.map((item) => (item.map(it => it.label)))
  let city_list_value = [];
  let city_list_label = [];
  for (
    let i = 0;
    i < cit_list.map((item) => item.map((it) => it.value)).length;
    i++
  ) {
    for (
      let j = 0;
      j < cit_list.map((item) => item.map((it) => it.value))[i].length;
      j++
    ) {
      city_list_value = city_list_value.concat(
        cit_list.map((item) => item.map((it) => it.value))[i][j]
      );
      city_list_label = city_list_label.concat(
        cit_list.map((item) => item.map((it) => it.label))[i][j]
      );
    }
  }
  for (let i = 0; i < city_list_value.length; i++) {
    city_list[city_list_value[i]] = city_list_label[i];
  }
  //区域
  let county_list_value = [];
  let county_list_label = [];
  for (
    let i = 0;
    i <
    cou_list.map((item) => item.map((it) => it.map((i) => i.value)))
      .length;
    i++
  ) {
    for (
      let j = 0;
      j <
      cou_list.map((item) => item.map((it) => it.map((i) => i.value)))[i]
        .length;
      j++
    ) {
      county_list_value = county_list_value.concat(
        cou_list.map((item) => item.map((it) => it.map((i) => i.value)))[
          i
        ][j]
      );
      county_list_label = county_list_label.concat(
        cou_list.map((item) => item.map((it) => it.map((i) => i.label)))[
          i
        ][j]
      );
    }
  }
  // console.log(county_list_value);
  for (let i = 0; i < county_list_value.length; i++) {
    county_list[county_list_value[i]] = county_list_label[i];
  }
  return {
    province_list, city_list, county_list
  }
}