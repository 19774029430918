/*
 * @Descripttion: project name
 * @version: 1.0
 * @Author: marui
 * @Date: 2023-04-02 09:14:28
 * @LastEditors: marui
 * @LastEditTime: 2023-04-11 14:52:43
 */
import axios from "axios";
// import router from "src/router/index.js";
import store from "@/store/index";

import { errorCode } from "@/common/js/config";

const minor = axios.create({ timeout: 30000 });

// minor.defaults.baseURL = 'http://192.168.100.100:8881/seller'
// minor.defaults.baseURL = 'https://m-shop.rzcf.com/seller/'
// minor.defaults.baseURL = "https://api-bf-seller.vyiot.com/seller";
// minor.defaults.baseURL = "http://192.168.100.75:8080/";
// minor.defaults.baseURL = "/prod-api";
// minor.defaults.baseURL = process.env.VUE_APP_BASE_API;  //测试http://zehao-h5.test.zmyiot.com/prod-api下的测试
minor.defaults.baseURL = process.env.VUE_APP_BASE_API +"/";  //生产
// minor.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';

// 请求拦截器
minor.interceptors.request.use(
  (config) => {
    config.headers.clickid = store.state.clickid;
    const token = "";
    token && (config.headers.Token = token);
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 响应拦截器
minor.interceptors.response.use(
  (response) => {
    const { code } = response.data;
    if (code === errorCode.ERR_NO) {
      // 参数错误
      console.log("参数错误", response.data);
    } else if (code === errorCode.ERR_BUSY) {
      // 服务器繁忙
      console.log("服务器繁忙", response.data);
    } else {
      return response.data;
    }
  },
  (error) => {
    const { response } = error;
    if (response) {
      // 请求已发出，但是不在2xx的范围
      // errorHandle(response.data.code, response.data)
      return Promise.reject(response);
    }
  }
);

export default minor;
