/*
 * @Descripttion: project name
 * @version: 1.0
 * @Author: marui
 * @Date: 2023-04-11 14:15:04
 * @LastEditors: marui
 * @LastEditTime: 2023-04-11 14:33:28
 */
import ajax from "../common/js/http.js";
import ajax2 from "../common/js/http2.js";

export function post(url) {
  return function (params) {
    return ajax.post(url, params).then((result) => {
      if (result) {
        return result;
      }
    });
  };
}

export function get(url) {
  return function (params = {}) {
    return ajax.get(url, { params }).then((result) => {
      if (result) {
        return result;
      }
    });
  };
}
/* ==================================================== */
export function post2(url) {
  return function (params) {
    return ajax2.post(url, params).then((result) => {
      if (result) {
        return result;
      }
    });
  };
}

export function get2(url) {
  return function (params = {}) {
    return ajax2.get(url, { params }).then((result) => {
      if (result) {
        return result;
      }
    });
  };
}
export function put(url) {
  return function (params) {
    return ajax.put(url, params).then((result) => {
      if (result) {
        return result;
      }
    });
  };
}

export function del(url) {
  return function (params) {
    const options = {
      data: params,
    };
    return ajax.delete(url, options).then((result) => {
      if (result) {
        return result;
      }
    });
  };
}
